<template>
  <ERow justify="center" align="stretch">
    <ECol cols="12" class="py-0">
      <v-data-table
        ref="projectsTable"
        v-model="selected"
        :headers="headers"
        :items="items"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        class="admin-data-table"
        dense
        must-sort
        :loading="loading"
        loading-text="Loading..."
        calculate-widths
        :mobile-breakpoint="0"
        :options.sync="options"
        :server-items-length="total"
        item-key="id"
        fixed-header
        :footer-props="{
          'items-per-page-options': [50, 100, 500],
        }"
        :hide-default-footer="total <= 50"
      >
        <template #top>
          <v-container fluid class="py-0">
            <ERow>
              <ECol cols="6" class="pl-0 pb-0 text-h6"> Projects </ECol>
              <v-spacer />
              <ECol cols="1" class="mb-1 pb-0 text-right">
                <CopyTableToClipboard
                  :headers="headers"
                  :items="filteredItems"
                />
              </ECol>
            </ERow>
          </v-container>
        </template>
        <template #item.name="{ item }">
          <nuxt-link
            :to="`/companies/${companyId}/${item.exid}`"
            @click.native="projectStore.editedProject = item"
          >
            {{ item.name }}
          </nuxt-link>
        </template>
        <template #item.status="{ item }">
          <span :class="getProjectStatusColor(item.status)">
            {{ getProjectStatusText(item.status) }}
          </span>
        </template>
        <template #item.startedAt="{ item }">
          {{ formatDate(item.startedAt) }}
        </template>
      </v-data-table>
    </ECol>
  </ERow>
</template>

<script>
import companiesProjectsTableHeader from "@/components/companies/companiesProjectsTableHeaders"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import ProjectUtils from "@/mixins/projectUtils"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { decamelize } from "humps"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/projects"

export default {
  components: {
    CopyTableToClipboard,
  },
  mixins: [ProjectUtils],
  props: {
    companyId: {
      type: [Number, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: {},
      total: 0,
      loading: true,
      sortBy: "name",
      sortDesc: true,
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    filteredItems() {
      let filteredItems = this.$refs.projectsTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchProjects()
      },
      deep: true,
    },
    companyId() {
      this.fetchProjects()
    },
  },
  async mounted() {
    this.headers = companiesProjectsTableHeader(this)
    await this.fetchProjects()
  },
  methods: {
    async fetchProjects() {
      if (!this.companyId) {
        return
      }
      try {
        this.loading = true
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        const params = {
          sort: `${decamelize(sortBy[0])}|${this.whichSort(sortDesc[0])}`,
          page: page,
          limit: itemsPerPage,
        }
        const response = await EvercamApi.companies.getCompanyProjects(
          this.companyId,
          {
            params,
          }
        )
        this.loading = false
        this.total = response.total
        this.items = response.items
      } catch (error) {
        this.$notifications.error({
          text: "Could not load Projects!",
          error,
        })
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
  },
}
</script>
